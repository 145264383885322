<template>
  <b-row>
    <b-col
      lg="8"
      sm="12"
      class="mx-auto"
    >
      <b-card>
        <b-card-text class="px-lg-5 px-1 py-2">
          <b-row>
            <b-col cols="12">
              <div class="text-center">
                <!-- <b-img
                  :src="avatarSrc"
                  v-bind="mainProps"
                  rounded="circle"
                  alt="Circle image"
                  class="d-inline-block mr-1 mb-1"
                /> -->

                <b-img
                  :src="avatarSrc"
                  rounded="circle"
                  class="d-inline-block mr-1 mb-1 m1"
                  style="width:120px; height:120px; background-color: gray;"
                />
              </div>
              <p class="text-center mb-0">
                Profile picture (format: jpg/jpeg, gif, png, square, size: 2MB max)
              </p>
              <div class="text-center">
                <center>
                  <b-form-file
                    v-model="avatar"
                    variant="secondary"
                    class="mt-1"
                    plain
                    accept="image/jpeg, image/png, image/gif"
                    style="border:1px solid #cccccc; width:50%"
                  >
                    <feather-icon
                      icon="FolderIcon"
                      size="18"
                    />&nbsp;&nbsp;Select Image
                  </b-form-file>
                  <b-button
                    class="mt-1 btn-sm"
                    variant="success"
                    :disabled="!hasImage"
                    @click="UpdateAvatar"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="18"
                    />&nbsp;&nbsp;Upload Image
                  </b-button>
                  &nbsp;
                  <b-button
                    class="mt-1 btn-sm"
                    variant="danger"
                    :disabled="!hasImage"
                    @click="removeAvatar"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="18"
                    />&nbsp;&nbsp;Remove Image
                  </b-button>

                </center>
              </div>
            </b-col>
          </b-row>
          <validation-observer ref="rules">
            <b-row class="mt-2">

              <b-col cols="12">
                <hr>
                <p class="text-center text-danger mx-2 mt-2 mb-2"><strong>NOTE:</strong> If you do not want to change your password,
                  the Password and Confirm Password fields should be empty.</p>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Password"
                  label-for="password"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    type="password"
                    placeholder="*********"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Confirm Password"
                  label-for="confirmPassword"
                >
                  <b-form-input
                    id="confirmPassword"
                    v-model="confirmPassword"
                    type="password"
                    placeholder="*********"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr class="my-2">
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="First Name *"
                  label-for="firstName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="firstName"
                    rules="required"
                  >
                    <b-form-input
                      id="firstName"
                      v-model="forename"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Last Name *"
                  label-for="lastName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="lastName"
                    rules="required"
                  >
                    <b-form-input
                      id="lastName"
                      v-model="surname"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Website"
                  label-for="website"
                >
                  <b-form-input
                    id="website"
                    v-model="website"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Email *"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Country *"
                  label-for="country"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <v-select
                      id="country"
                      v-model="selectedCountry"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countries"
                      label="name"
                      @input="setSelectedCountryCode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="6">
                <b-form-group
                  label="Regional Unit *"
                  label-for="regionalUnit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="regionalUnit"
                    rules="required"
                  >
                    <v-select
                      id="regionalUnit"
                      v-model="selectedRegion"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="regions"
                      label="name"
                      @input="setSelectedRegion"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col cols="12">
                <b-form-group
                  label="City / Location *"
                  label-for="cityLocation"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cityLocation"
                    rules="required"
                  >
                    <b-form-input
                      id="cityLocation"
                      v-model="city"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Address 1 *"
                  label-for="address1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="address1"
                    rules="required"
                  >
                    <b-form-input
                      id="address1"
                      v-model="address1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Address 2"
                  label-for="address2"
                >
                  <b-form-input
                    id="address2"
                    v-model="address2"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Post Code *"
                  label-for="postCode"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="postCode"
                    rules="required"
                  >
                    <b-form-input
                      id="postCode"
                      v-model="postcode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="6">
                <b-form-group
                  label="Phone # *"
                  label-for="phoneNos"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phoneNos"
                    rules="required"
                  >
                    <b-form-input
                      id="phoneNos"
                      v-model="phone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col cols="6">
                <b-form-group
                  label="Skype ID"
                  label-for="skypeID"
                >
                  <b-form-input
                    id="skypeID"
                    v-model="skype"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-2 d-flex justify-content-center"
              >
                <b-form-checkbox
                  v-model="selected.newsletter"
                  :value="subscribe_news"
                  @change="changeSubscribe('newsletter')"
                >
                  Subscribe to our newsletter
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                class="mt-2 d-flex justify-content-center"
              >
                <b-form-checkbox
                  v-model="selected.product"
                  :value="subscribe_prod"
                  @change="changeSubscribe('product')"
                >
                  Subscribe to our product updates
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                class="text-center"
              >
                <b-button
                  variant="primary"
                  class="mt-1 align-items-center"
                  :disabled="sending"
                  @click="updateProfile"
                >
                  <span v-if="sending">
                    <b-spinner
                      small
                      type="grow"
                    />
                    Saving...
                  </span>
                  <span
                    v-else
                    class="d-flex align-items-center"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="18"
                    />&nbsp;&nbsp;Update
                  </span>

                </b-button>
              </b-col>

            </b-row>
          </validation-observer>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton, BImg, BFormInput, BFormGroup, BFormCheckbox, BSpinner, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import Vue from 'vue'

export const eventBus = new Vue()

const base64Encode = data => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(data)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BImg,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BSpinner,
    BFormFile,
  },
  data() {
    return {
      defaultBGround: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',
      avatar: null,
      avatarSrc: null,
      sending: false,
      isupload: false,
      emitAvatar: null,
      id: 0,
      forename: '',
      surname: '',
      email: '',
      company: '',
      address1: '',
      address2: '',
      skype: '',
      postcode: '',
      phone: '',
      website: '',
      city: '',
      country: '',
      selectedCountry: '',
      selectedRegion: '',
      subscribe_news: 1,
      subscribe_prod: 1,
      news_sub: 0,
      region: '',
      selected: {
        newsletter: [],
        product: [],
      },
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 120,
        height: 120,
        class: 'm1',
      },
      password: '',
      confirmPassword: '',
      user_status: 0,
      product_updates_sub: 0,
    }
  },
  computed: {
    hasImage() {
      return !!this.avatar
    },
    user() {
      return this.$store.state.auth.userData
    },
    countries() {
      return this.$store.state.auth.countries
    },
    regions() {
      return this.$store.state.auth.regions
    },
    userAvatar() {
      return this.$store.state.auth.userAvatar
    },
  },
  watch: {
    avatar(newValue, oldValue) {
      if (newValue !== oldValue) {
        const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.jpg|.jpeg|.gif|.png|.bmp)$/
        if (newValue.type !== null && !regex.test(newValue.type.toLowerCase())) {
          this.notify({
            text: 'Please use a valid image avatar. It should have the following extension jpg, jpeg, gif, png or bmp.',
            variant: 'danger',
          })
        } else if (newValue.size > 2097152) {
          this.notify({
            text: 'The image size is greater than the allowed size limit.',
            variant: 'danger',
          })
        } else if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.avatarSrc = value
            })
            .catch(() => {
              this.avatarSrc = this.defaultBGround
            })
        } else {
          this.avatarSrc = this.defaultBGround
        }
      }
    },
  },
  mounted() {
    this.setUser()
    this.getCountryName()
    this.getRegionName()
    this.setInitUserAvatar()
  },
  methods: {
    setUser() {
      // if user is limited, redirect to home
      if (this.user.limited) {
        this.$router.push({ name: 'home' })
      }
      this.id = this.user.id
      this.forename = this.user.forename
      this.surname = this.user.surname
      this.email = this.user.email
      this.company = this.user.company
      this.address1 = this.user.address1
      this.address2 = this.user.address2
      this.skype = this.user.skype
      this.postcode = this.user.postcode
      this.phone = this.user.phone
      this.website = this.user.website
      this.city = this.user.city
      this.country = this.user.country
      this.news_sub = this.user.news_sub
      this.product_updates_sub = this.user.product_updates_sub
      this.region = this.user.region
      this.user_status = this.user.user_status
      if (this.news_sub) this.selected.newsletter = [1]
      if (this.product_updates_sub) this.selected.product = [1]
    },
    changeSubscribe(type) {
      if (type === 'newsletter') {
        if (this.selected.newsletter.includes(1)) {
          this.news_sub = 1
        } else {
          this.news_sub = 0
        }
      } else if (type === 'product') {
        if (this.selected.product.includes(1)) {
          this.product_updates_sub = 1
        } else {
          this.product_updates_sub = 0
        }
      }
    },
    getCountryName() {
      this.selectedCountry = this.countries.find(obj => obj.code === this.country)
    },
    getRegionName() {
      this.selectedRegion = this.regions.find(obj => Number(obj.id) === Number(this.region))
    },
    setSelectedCountryCode() {
      this.country = this.selectedCountry.code
      const payload = { country: this.country }
      this.$store.dispatch('auth/updateRegions', payload)
        .then(res => { this.selectedRegion = ''}) // eslint-disable-line
    },
    setSelectedRegion() {
      this.region = this.selectedRegion.id
    },
    setInitUserAvatar() {
      const { avatar } = this.userAvatar
      if (avatar) {
        this.avatarSrc = avatar
      }
    },
    updateProfile() {
      this.$refs.rules.validate().then(success => {
        if (!success) {
          this.notify({
            text: 'Please fill-in the required fields.',
            variant: 'danger',
          })
        } else {
          const finalpassword = this.password.trim()
          const confirmPassword = this.confirmPassword.trim()
          if (confirmPassword !== finalpassword) {
            this.notify({
              text: 'Password field is not equal to Confirm Password field.',
              variant: 'danger',
            })
            return
          }
          this.sending = true
          const payload = {
            id: this.id,
            uid: this.user.uid,
            forename: this.forename,
            surname: this.surname,
            password: finalpassword,
            email: this.email,
            country: this.country,
            region: this.region,
            city: this.city,
            address1: this.address1,
            address2: this.address2,
            postcode: this.postcode,
            phone: this.phone,
            skype: this.skype,
            website: this.website,
            avatar: this.user.avatar,
            userRole: this.user.userRole,
            isAdmin: this.user.isAdmin,
            company: this.user.company,
            news_sub: this.news_sub,
            product_updates_sub: this.product_updates_sub,
          }

          this.$store.dispatch('auth/updateProfile', payload)
            .then(res => {
              if (res?.success === 'OK') {
                this.sending = false
                this.notify({
                  text: 'Profile is successfully updated!',
                  variant: 'success',
                })
              } else {
                if (!res?.msg) {
                  this.notify({
                    text: 'No data to change.',
                    variant: 'warning',
                  })
                  this.sending = false
                  return
                }
                const msg1 = JSON.stringify(res.msg)
                const msg = JSON.parse(msg1)
                const key = Object.keys(msg)
                const ErrorMsg = msg[key].toString()
                this.notify({
                  text: ErrorMsg,
                  variant: 'danger',
                })
                this.sending = false
              }
            })
        }
      })
    },
    removeAvatar() {
      this.avatarSrc = this.defaultBGround
      this.avatar = null
    },
    UpdateAvatar() {
      const payload = {
        id: this.id,
        avatar: this.avatarSrc,
      }
      this.emitAvatar = payload

      this.$store.dispatch('auth/updateUserAvatar', payload)
        .then(res => {
          if (res) {
            this.notify({
              text: 'You have just successfully changed your avatar!',
              variant: 'success',
            })
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.notify({
              text: 'Failed to change your avatar!',
              variant: 'danger',
            })
          }
        })
      eventBus.$emit('updateAvatar', this.emitAvatar)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

input.vs__search::placeholder {
  color: #adadad;
}
</style>

<style lang="scss" scoped>
.ch-title {
  position: relative;
  top: -2px;
}

.warning-section {
  background: #f44336;
  color: #fff;
}
.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}
</style>
